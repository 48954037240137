<template>
  <div v-if="!subscribed" class="flex flex-col items-center m-5">
    <div v-if="!submitted">
      <h2 class="text-center">Become a {{ business.name }} VIP</h2>
      <p class="mt-2 mb-4 text-gray-700 text-center">Get specials, coupons, and exclusive offers via email or text message</p>
    </div>

    <div v-if="submitted">
      <h2 class="text-center">Great!</h2>
      <p class="mt-2 mb-4 text-gray-700 text-center">You’re on the list. Special offers will be coming your way soon.</p>
    </div>

    <div v-if="!submitted" class="container-capture mx-5">
      <input
        v-model="lead"
        type="text"
        class="input-capture"
        placeholder="Your email or phone number"
        @keyup.enter="captureLead"
      />
      <button class="btn-submit" @click="captureLead">
        <IconArrowUp :size="24" fill="white" style="transform:rotate(90deg)"/>
      </button>
    </div>


  </div>
</template>

<script>
import IconArrowUp from '@/components/icons/IconArrowUp';

import { mapState } from 'vuex';

import axios from 'axios';

export default {
  name: 'LeadCapture',
  props: {
    source: String
  },
  data() {
    return {
      subscribed: false,
      submitted: false,
      lead: null
    }
  },
  computed: {
    ...mapState([
      'business',
      'config'
    ])
  },
  methods: {
    captureLead() {
      if (!this.lead) return;
      let data = {
        businessId: this.config,
        source: this.source
      }

      // XXX TODO Better validation
      if (this.lead.includes('@')) {
        data.email = this.lead
      } else {
        data.phoneNumber = this.lead
      }

      axios.post('https://api.bend.io/rpc/kfXUG1kxTRO6aqCt9tl4Tg/custom/add-subscriber', data,
        {
          auth: {
            username: 'kfXUG1kxTRO6aqCt9tl4Tg',
            password: 'u1QzSbS8zCjIPJhMhdjwLpeczXw5FtAJSrV6CDRe'
          }
        }
      ).then(res => {
        console.log(res)
        this.submitted = true
        localStorage.setItem(`${this.config}-subscribed`, true)
      }).catch(error => {
        console.log('subscribe error', error)
      })
    }
  },
  mounted() {
    const subscription = localStorage.getItem(`${this.config}-subscribed`)
    if (subscription) {
      // don't really care what the value is, just that it exists
      // this means this user has previously subscribed to this business
      // and doesn't need to see the lead capture form anymore.
      this.subscribed = true
    }
  },
  components: {
    IconArrowUp
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-capture {
  display: flex;
  border-radius: 8px;
  height: 56px;
  width: 100%;
}

.input-capture {
  flex:1;
  padding: 0 12px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: box-shadow ease-in-out 0.3s;
}

.input-capture::placeholder {
  color: var(--gray-300)
}

.input-capture:focus {
  outline:none;
  box-shadow: 4px 4px 12px rgba(0,0,0,0.1);
}

.btn-submit {
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>
