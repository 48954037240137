<template>
<svg :width="size + 'px'" :height="size + 'px'" :fill="fill" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
<g>
	<g>
		<path d="M21.2,6.8H8.2C7.8,6.8,7.4,6.4,7.4,6c0-0.4,0.3-0.8,0.8-0.8h13.1C21.7,5.2,22,5.6,22,6C22,6.4,21.7,6.8,21.2,6.8z"/>
	</g>
	<g>
		<path d="M21.2,12.8H8.2c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h13.1c0.4,0,0.8,0.3,0.8,0.8S21.7,12.8,21.2,12.8z"/>
	</g>
	<g>
		<path d="M21.2,18.8H8.2c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8h13.1c0.4,0,0.8,0.3,0.8,0.8C22,18.5,21.7,18.8,21.2,18.8z"
			/>
	</g>
	<g>
		<path d="M3.2,7.1C2.5,7.1,2,6.6,2,6s0.5-1.2,1.2-1.2l0,0c0.7,0,1.2,0.5,1.2,1.2S3.8,7.1,3.2,7.1z"/>
	</g>
	<g>
		<path d="M3.2,13.2C2.5,13.2,2,12.7,2,12s0.5-1.2,1.2-1.2l0,0c0.7,0,1.2,0.5,1.2,1.2S3.8,13.2,3.2,13.2z"/>
	</g>
	<g>
		<path d="M3.2,19.2C2.5,19.2,2,18.7,2,18s0.5-1.2,1.2-1.2l0,0c0.7,0,1.2,0.5,1.2,1.2C4.2,18.7,3.8,19.2,3.2,19.2z"/>
	</g>
</g>
</svg>
</template>

<script>
export default {
  name: 'IconList',
  props: {
		size: Number,
		fill: String
	}
}
</script>