<template>
  <transition name="outer">
    <div class="viewer-container" ref="viewer" v-show="menuItem">

      <transition name="trap">
        <div v-if="menuItem" class="viewer-trap"/>
      </transition>

      <transition name="vertical-slide">
        <div v-if="menuItem" class="viewer-content">
          <div class="viewer-card">
            <img class="item-image" :alt="`Image of ${menuItem.name}`" :src="menuItem.imageUrl"/>
            <div class="viewer-copy-container flex-grow flex flex-col">
              <div class="flex items-center">
                <span class="item-title">{{ menuItem.name }}</span>
                <span class="ml-2" style="color: var(--primary-color)">{{ menuItem.price }}</span>
              </div>
              <div class="w-full mt-2">
                <p class="viewer-item-description" v-html="formatDescription(menuItem.description)"/>
              </div>
              <div class="flex flex-grow" />
              <div class="w-full flex">
                <button :class="{active:faved}" class="flex-grow btn-round p-3 mr-3 mt-5 mb-10" @click="toggleFavorite">
                  <IconHeartOutlined class="mx-2" v-if="!faved" :size="24" fill="var(--gray-700)"/>
                  <IconHeart class="mx-2" v-if="faved" :size="24" fill="var(--primary-color)" />
                 {{ faved ? 'Added to my list' : 'Add to my list'}}
                </button>
                <button  @click="deselectMenuItem" class="btn-round p-3 mx-auto mt-5 mb-10">
                  <IconClose :size="24" fill="var(--gray-700)" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import HtmlFormatService from "@/services/HtmlFormatService";
import Hammer from 'hammerjs'
import IconClose from "@/components/icons/IconClose";
import IconHeart from "@/components/icons/IconHeart";
import IconHeartOutlined from "@/components/icons/IconHeartOutlined";

export default {
  name: "ItemImageViewer",
  components: {IconHeartOutlined, IconHeart, IconClose},
  props: {
    menuItem: {
      default: () => ({}),
      type: Object,
    },
    section: {
      default: () => ({}),
      type: Object
    }
  },
  methods: {
    ...mapMutations(['deselectMenuItem', 'toggleFave']),
    formatDescription: HtmlFormatService.formatDescription,
    toggleFavorite() {
      this.toggleFave({itemId: this.menuItem._id, menuId: this.currentMenu._id});
    }
  },
  computed: {
    ...mapGetters(['currentMenu']),
    ...mapState(['activeMenuId', 'faves']),
    faved() {
      //todo(miko): We can probably find a better way of doing this...
      return !!this.faves[this.currentMenu._id][this.menuItem._id]
    },
  },
  mounted() {
    let hammer = new Hammer(this.$refs['viewer'])
    hammer.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
    hammer.on('swipedown', () => this.deselectMenuItem())
  }
}
</script>

<style scoped>

:root {
  --duration: 10s
}

.btn-round.active {
  box-shadow: var(--shadow-pressed);
  background: var(--gray-200);
}

.viewer-container {
  top: 0;
  z-index: 5;
  position: fixed;
  height: 100%;
  width: 100%;
}

.viewer-trap {
  opacity: 0.8;
  height: 100%;
  width: 100%;
  background: black;
  top: 0;
  z-index: 5;
}

.viewer-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.viewer-content {
  position: fixed;
  bottom: 0;
  z-index: 10;
  background: var(--gray-100);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: var(--card-radius) var(--card-radius) 0 0;
  height: 85%;
  width: 100%;
}

.viewer-copy-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.item-image {
  width: 100%;
  height: 50%;
  object-fit: cover;
  border-radius: var(--card-radius) var(--card-radius) 0 0;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.viewer-item-price {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #646464;
}

.viewer-item-description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #646464;
  white-space: pre-wrap;
}

/*Trap Opacity Animation*/

.trap-enter-active {
  transition: all var(--card-animation-duration) ease-out;
}

.trap-leave-active {
  transition: all var(--card-animation-duration) ease-out;
}

.trap-enter, .trap-leave-to {
  opacity: 0;
}

/*Card Slide In Animation*/

.vertical-slide-leave-active {
  transition: all var(--card-animation-duration) ease-out;
}

.vertical-slide-enter, .vertical-slide-leave-to {
  transform: translateY(80%);
  opacity: 0;
}

.vertical-slide-enter-active {
  transition: all var(--card-animation-duration) ease-out;
}

/*Outer Animation That Just Keeps The Component On Screen*/

.outer-enter-active {
  transition: all var(--card-animation-duration) ease-out;
}

.outer-leave-active {
  transition: all var(--card-animation-duration) ease-out;
}

.outer-enter, .outer-leave-to {
  /* noop */
}

</style>
