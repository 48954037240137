<template>
  <section v-if="section" class="menu-section" :class="firstSection ? 'pt-24' : 'pt-8'" :id="`section-${section._id}`">
    <div v-if="showSectionTitle" class="flex">
      <div aria-hidden="true" class="header-border header-border-left flex-grow mt-2"></div>
      <h3 class="flex-shrink mx-3 text-gray-700">{{ section.name }}</h3>
      <div aria-hidden="true" class="header-border header-border-right flex-grow mt-2"></div>
    </div>

    <p class="text-gray-700 text-center mx-3 mt-1 mb-6">{{section.description}}</p>

    <MenuItem v-for="item in section.items" :key="item.id" :item="item" :section="section" />

  </section>
</template>

<script>
import MenuItem from './MenuItem';

export default {
  name: 'MenuSection',
  props: {
    section: Object,
    firstSection: Boolean,
    showSectionTitle: Boolean
  },
  components: {
    MenuItem
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-border {
  height: 20px;
  border-top: 1px solid #D2D2D2;
}

.header-border-left {
  border-left: 1px solid #D2D2D2;
  border-top-left-radius: 20px;
}

.header-border-right {
  border-right: 1px solid #D2D2D2;
  border-top-right-radius: 20px;
}

.bottom-border {
  height: 20px;
  border: 1px solid #D2D2D2;
  border-top: none;
  box-shadow: 1px 1px 1px white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
</style>
