<template>
  <div ref="menu">
    <!-- DEFAULT NAVBAR -->
    <Navbar v-if="currentMenu && !sectionPickerOpen && !favesOpen" />

    <ItemImageViewer :menu-item="selectedMenuItem" />

    <!-- MENU PICKER -->
    <MenuPicker v-if="menuPickerOpen" class="list-content" />

    <!-- FAVES LIST -->
    <FavesList v-if="favesOpen" class="list-content" />

    <!-- CURRENT MENU -->
    <div v-if="currentMenu" class="ios-bottom-padding-fix">
      <div v-if="!sectionPickerOpen && !favesOpen && !menuPickerOpen">
        <MenuSection
          v-for="(section, index) in currentMenu.sections"
          :key="section._id"
          :section="section"
          :firstSection="index === 0"
          :showSectionTitle="true"
        />
      </div>
      <LeadCapture v-if="!sectionPickerOpen && !favesOpen && !menuPickerOpen" source="menu-footer" />

      <Fab v-if="!menuPickerOpen && !favesOpen && currentMenu.sections.length > 1"/>

      <SectionPicker v-if="!menuPickerOpen && !favesOpen" />

      <!-- SCROLL TO TOP BUTTON -->
      <button v-show="menuLargerThanViewport" class="btn-round px-4 py-3 mx-auto mt-8 mb-6" @click="scrollToTop">
        <IconArrowUp :size="20" fill="var(--gray-700)"/>
        <span class="text-gray-700 ml-1">Back to Top</span>
      </button>
    </div>

    <!-- INDEX PAGE -->
    <MenuIndex v-if="showIndex"/>

    <!-- ERROR MESSAGE -->
    <div v-if="menuError" v-html="menuError" />
  </div>
</template>

<script>
// @ is an alias to /src
import MenuSection from '@/components/MenuSection';
import LeadCapture from '@/components/LeadCapture';
import MenuIndex from '@/components/MenuIndex';
import Navbar from '@/components/Navbar';
import FavesList from '@/components/FavesList';
import MenuPicker from '@/components/MenuPicker';
import Fab from '@/components/Fab';
import SectionPicker from '@/components/SectionPicker';

import IconArrowUp from '@/components/icons/IconArrowUp';

import { mapState, mapGetters } from 'vuex';
import ItemImageViewer from "@/components/ItemImageViewer";

export default {
  name: 'Home',
  components: {
    ItemImageViewer,
    MenuSection,
    LeadCapture,
    MenuIndex,
    Navbar,
    FavesList,
    MenuPicker,
    Fab,
    SectionPicker,
    IconArrowUp
  },
  data() {
    return {
      scrollPositionY: null,
      menuObserver: null,
      menuLargerThanViewport: false,
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({left:0, top:0, behavior: 'smooth'})
    }
  },
  computed: {
    ...mapState([
      'sectionId',
      'menuError',
      'favesOpen',
      'menuPickerOpen',
      'sectionPickerOpen',
      'business',
      'configLoaded',
      'activeMenuId',
      'menus',
      'selectedMenuItem'
    ]),
    ...mapGetters([
      'currentMenu'
    ]),
    showIndex() {
      // console.log('showIndex')
      let show = this.configLoaded && this.business.showIndex
      show = show && (this.activeMenuId === null)
      // console.log('this.activeMenuId', this.activeMenuId)
      return show;
    }
  },
  watch: {
    sectionId() {
      setTimeout(() => {
        let section = document.querySelector(`#section-${this.sectionId}`)
        if (section) {
          window.scrollTo({left: 0, top: section.offsetTop - 60, behavior: 'smooth'})
          if (this.$route.hash !== `#${this.sectionId}`) {
            this.$router.replace({hash: this.sectionId})
          }
        }
      }, 100)
    },
    configLoaded() {
      // console.log('configLoaded')
      console.log('route', this.$route)
      if (this.$route.params.menu) {
        let menuForRoute = this.menus.find(menu => menu.slug === this.$route.params.menu)
        if (menuForRoute) {
          this.$store.commit('setActiveMenuSlug', menuForRoute.slug)
        }
      }

      if (this.$route.hash) {
        let sectionId = decodeURI(this.$route.hash.slice(1))
        setTimeout(() => {
          let menuSection = document.querySelector(`#section-${sectionId}`)
          if (menuSection) {
            this.$store.commit('setSectionId', sectionId)
          }
        }, 300)
      }

    },
    '$route.path'() {
      console.log('route change', this.$route)
      if (!this.$route.params.menu) {
        this.$store.commit('setActiveMenuSlug', null)
      } else {
        this.$store.commit('setActiveMenuSlug', this.$route.params.menu)
      }
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100)
    },
    '$route.hash'() {
      let sectionId = decodeURI(this.$route.hash.slice(1))
      setTimeout(() => {
        let menuSection = document.querySelector(`#section-${sectionId}`)
        if (menuSection) {
          this.$store.commit('setSectionId', sectionId)
        }
      }, 300)
    }

  },
  mounted() {

    this.$store.dispatch('loadConfig', {config: this.$route.params.config, slug: this.$route.params.slug})

    // This will hide / show the back to top button based on whether the menu container scrolls or not.
    this.menuObserver = new MutationObserver(() => {
      this.menuLargerThanViewport = this.$refs['menu'].getBoundingClientRect().height > window.innerHeight + 50
    })
    this.menuObserver.observe(this.$refs['menu'], { attributes:false, childList: true, subtree: true })

  },
  destroyed() {
    this.menuObserver.disconnect();
  }
}
</script>

<style scoped>
.list-content {
  z-index: 2;
  transition: opacity ease-in-out 0.3s;
}

.list-backdrop {
  position: fixed;
  width: 100vw;
  transition: all ease-in-out 0.3s;
  z-index: 1;
  pointer-events: none;
}

.ios-bottom-padding-fix {
  /* this fixes a bug in ios where unless 1px of padding exists at the bottom, all margins will be ignored */
  padding-bottom: 1px;
}

</style>
