<template>
  <div class="button-group p-3 bg-gray-100" :class="navbarShadow">

    <!-- MENU TITLE & TOGGLE -->
    <button @click="toggleMenuPicker" class="flex items-center text-left" :disabled="menus.length === 1">
      <IconChevron
      v-if="menus.length > 1"
        :size=22
        fill="var(--gray-700)"
        :style="[menuPickerOpen ? {'transform': 'rotate(180deg)'} : {'transform': 'rotate(0deg)'}]"
        style="transition: transform 0.3s"/>

      <div class="ml-2">
        <div class="text-gray-700">
        {{ business.name }}
        </div>
        <h2>
        {{ currentMenu.name }}
        </h2>
      </div>
    </button>

    <!-- FAV BUTTON -->
    <button v-if="!menuPickerOpen" @click="toggleFaves" class="btn-round px-4 py-3">
      <IconHeart :size=24 fill="var(--primary-color)"/>
      <span class="text-gray-700 ml-1">{{ faveCount }}</span>
    </button>

  </div>
</template>

<script>
import IconChevron from '@/components/icons/IconChevron';
import IconHeart from '@/components/icons/IconHeart';

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Navbar',
  components: {
    IconChevron,
    IconHeart
  },
  data() {
    return {
      scrollPosition: null
    }
  },
  computed: {
    ...mapState([
      'favesOpen',
      'menuPickerOpen',
      'sectionPickerOpen',
      'menus',
      'business',
      'activeMenuId'
    ]),
    ...mapGetters([
      'faveCount',
      'currentMenu'
    ]),
    navbarShadow() {
      return {
        'shadow-md': this.scrollPosition >12 && !this.menuPickerOpen
      }
    }
  },
  methods: {
    updateScroll() {
       this.scrollPosition = window.scrollY
    },
    toggleMenuPicker() {
      this.$store.commit('setMenuPickerOpen', !this.menuPickerOpen)
    },
    toggleFaves() {
      this.$store.commit('setFavesOpen', !this.favesOpen)
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.updateScroll);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button-group {
  display: flex;
  width: 100vw;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 3;
}
</style>
