const HtmlFormatService = {
  formatDescription,
}

function formatDescription(description) {

  if (!description) {
    return null;
  }

  description = description.replace(/[\r\n]+/g, "<br>")

  let re = new RegExp(/((\$([0-9]+)([.][0-9]+)?))/g)
  let matchData = re.exec(description)
  let matches = []
  while (matchData) {
    let match = matchData[0]
    let index = matchData.index
    matches.push({ match, index })
    matchData = re.exec(description)
  }

  let out = ''
  let lastPos = 0
  if (matches.length) {
    for (let i = 0; i < matches.length; i++) {
      let matchData = matches[i]
      out += description.slice(lastPos, matchData.index)
      out += `<span style="color: var(--primary-color)">${matchData.match}</span>`
      lastPos = matchData.index + matchData.match.length
    }
  } else {
    out = description
  }
  out = out.replace(/[\r\n]+/g, "<br>")

  return out

}

export default HtmlFormatService;
