<template>
<svg :width="size + 'px'" :height="size + 'px'" :fill="fill" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
<path d="M13.4,12l7.3-7.3c0.4-0.4,0.4-1.1,0-1.4c-0.4-0.4-1.1-0.4-1.4,0L12,10.6L4.7,3.3c-0.4-0.4-1.1-0.4-1.4,0s-0.4,1.1,0,1.4
	l7.3,7.3l-7.3,7.3c-0.4,0.4-0.4,1.1,0,1.4C3.5,20.9,3.7,21,4,21c0.2,0,0.5-0.1,0.7-0.3l7.3-7.3l7.3,7.3c0.2,0.2,0.5,0.3,0.7,0.3
	s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1.1,0-1.4L13.4,12z"/>
</svg>

</template>

<script>
export default {
  name: 'IconClose',
  props: {
    size: Number,
    fill: String
	}
}
</script>