import { render, staticRenderFns } from "./IconList.vue?vue&type=template&id=2c5dd9a4&"
import script from "./IconList.vue?vue&type=script&lang=js&"
export * from "./IconList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports