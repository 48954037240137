<template>
	<svg :width="size + 'px'" :height="size + 'px'" x="0px" y="0px" :fill="fill" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
		<g>
			<path d="M20.2,17.4H3.8c-0.4,0-0.8-0.2-0.8-0.7c-0.1-0.4,0-0.8,0.3-1c0,0,2.3-1.8,2.3-7.4c0-1.7,0.7-3.3,1.8-4.5S10.3,2,12,2
				s3.3,0.7,4.5,1.8s1.8,2.8,1.8,4.5c0,5.7,2.3,7.4,2.3,7.4c0.3,0.2,0.5,0.7,0.3,1C20.9,17.2,20.6,17.4,20.2,17.4z M5.8,15.7h12.5
				c-0.8-1.3-1.7-3.7-1.7-7.2c0-1.2-0.5-2.3-1.3-3.2c-0.8-0.8-2-1.3-3.2-1.3S9.7,4.3,8.8,5.2C7.9,6,7.4,7.2,7.4,8.4
				C7.4,12,6.6,14.2,5.8,15.7z"/>
		</g>
		<g>
			<path d="M12,22c-0.5,0-0.9-0.2-1.3-0.3c-0.4-0.2-0.8-0.6-1-1s-0.1-1,0.3-1.2c0.4-0.2,1-0.1,1.2,0.3c0.1,0.2,0.2,0.2,0.3,0.3
				c0.2,0.2,0.7,0.2,0.9,0c0.2-0.1,0.2-0.2,0.3-0.3c0.2-0.4,0.8-0.6,1.2-0.3c0.4,0.2,0.6,0.8,0.3,1.2s-0.6,0.8-1,1
				C12.9,21.8,12.5,22,12,22z"/>
		</g>
	</svg>
</template>

<script>
export default {
  name: 'IconBell',
  props: {
		size: Number,
		fill: String
	}
}
</script>