<template>
  <!-- BUTTON -->
  <button class="btn-fab" @click="toggleFab">
    <IconList v-if="!sectionPickerOpen" :size=32 fill="white" />
    <IconClose v-if="sectionPickerOpen" :size=24 fill="white" @click="toggleFab" />
  </button>
</template>

<script>
import IconList from './icons/IconList';
import IconClose from './icons/IconClose';

import { mapState } from 'vuex';

export default {
  name: 'Fab',
  computed: {
    ...mapState([
      'sectionPickerOpen'
    ])
  },
  methods: {
    toggleFab() {
      this.$store.commit('setSectionPickerOpen', !this.sectionPickerOpen)
    }
  },
  components: {
    IconList,
    IconClose
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-fab {
  z-index: 4;
  background: var(--primary-color);
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition:all ease-in-out 0.2s;
  box-shadow: 2px 2px 8px var(--primary-color);
}
</style>
