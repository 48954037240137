<template>
	<svg :width="size + 'px'" :height="size + 'px'" :fill="fill" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
	<g>
		<path d="M21.3,4.2c-0.5-0.5-1.2-1-1.8-1.3c-0.7-0.3-1.5-0.5-2.2-0.5c-0.7,0-1.6,0.2-2.2,0.5c-0.7,0.3-1.4,0.7-1.8,1.3L12,5.3
			l-1.1-1.1C9.8,3.1,8.3,2.5,6.8,2.5s-3,0.6-4,1.7C1.6,5.3,1,6.8,1,8.3s0.6,3,1.7,4.1l1.1,1.1l8.2,8.2l8.2-8.2l1.1-1.1
			c0.6-0.6,1-1.2,1.3-1.8C22.8,9.8,23,9.1,23,8.3s-0.2-1.6-0.5-2.2C22.3,5.4,21.8,4.8,21.3,4.2z"/>
	</g>
</svg>
</template>

<script>
export default {
  name: 'IconHeart',
  props: {
		size: Number,
		fill: String
	}
}
</script>