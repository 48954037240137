<template>
  <div class="m-3 mb-6 clearfix relative">

    <!-- FAV BUTTON ALONE-->
    <button :aria-label="favoriteButtonText" v-if="!item.imageUrl" class="p-2 -mt-2 float-right" @click="toggleFave(item._id)">
      <IconHeartOutlined v-if="!faved" :size=24 fill="var(--gray-700)"/>
      <IconHeart v-if="faved" :size=24 fill="var(--primary-color)"/>
    </button>

    <!-- IMAGE -->
    <button v-if="item.imageUrl" class="item-button float-right ml-2 mb-2" @click="() => selectMenuItem(item)">
      <div
        class="item-image"
        :style="`background-image: url('${item.imageUrl}`" />
    </button>

    <!-- TITLE + PRICE -->
    <div>
      <span class="item-title"> {{ item.name }} </span>
      <span v-if="item.price" style="color: var(--primary-color)" class="ml-1">
        {{ item.price }}
      </span>
    </div>

    <!-- DESCRIPTION -->
    <p class="text-gray-700" v-html="styledDescription" />

  </div>
</template>

<script>
import IconHeart from '@/components/icons/IconHeart';
import IconHeartOutlined from '@/components/icons/IconHeartOutlined';

import {mapMutations, mapState} from 'vuex'
import HtmlFormatService from "@/services/HtmlFormatService";

export default {
  name: 'MenuItem',
  props: {
    item: Object,
    section: Object,
  },
  components: {
    IconHeart,
    IconHeartOutlined
  },
  computed: {
    ...mapState([
      'faves',
      'activeMenuId'
    ]),
    faved() {
      let faved = this.faves[this.section.menuId][this.item._id]
      return faved
    },
    styledDescription() {
      return HtmlFormatService.formatDescription(this.item.description)
    },
    favoriteButtonText() {
      return this.faved ? 'Remove From Favorites' : 'Add to favorites'
    }
  },
  methods: {
    ...mapMutations([
      'selectMenuItem'
    ]),
    toggleFave(itemId) {
      this.$store.commit('toggleFave', {menuId: this.section.menuId, itemId})
    }
  }
}
</script>

<style scoped>

.item-button {
  height: 80px;
  width: 80px;
}

.item-image {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.fav-on-image {
  position: absolute;
  right: 0;
  top: 0;
  background-image: url("../assets/shadow-heart.png");
  background-size: contain;
}
</style>
