<template>
  <svg :width="size + 'px'" :height="size + 'px'" x="0px" y="0px" :fill="fill" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
    <g>
      <path d="M12,17.7c-0.3,0-0.7-0.2-1-0.4L2.4,8.8c-0.6-0.6-0.6-1.5,0-2s1.5-0.6,2,0l7.6,7.6l7.6-7.6c0.6-0.6,1.5-0.6,2,0
        c0.6,0.6,0.6,1.5,0,2L13,17.3C12.7,17.6,12.3,17.7,12,17.7z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconChevron',
  props: {
    size: Number,
    fill: String
  }
}
</script>
