<template>
	<svg :width="size + 'px'" :height="size + 'px'" x="0px" y="0px" :fill="fill" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
   <path d="M21.4,11.7l-8-8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.2-0.9,0.3l-8,8c-0.4,0.4-0.4,1.2,0,1.6c0.3,0.3,0.5,0.3,0.8,0.3
	c0.3,0,0.6-0.1,0.8-0.3l6-6v13.3c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1V7.2l6,6c0.4,0.4,1.2,0.4,1.6,0
	C21.8,12.8,21.8,12.2,21.4,11.7z"/>
	</svg>
</template>

<script>
export default {
  name: 'IconArrowUp',
  props: {
		size: Number,
		fill: String
	}
}
</script>
