<template>
  <svg :width="size + 'px'" :height="size + 'px'" x="0px" y="0px" :fill="fill" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
    <g>
      <path d="M12,23C5.9,23,1,18.1,1,12S5.9,1,12,1s11,4.9,11,11S18.1,23,12,23z M12,3c-5,0-9,4-9,9c0,5,4,9,9,9c5,0,9-4,9-9
        C21,7,17,3,12,3z"/>
    </g>
    <g>
      <path d="M12,17c-3,0-4.7-2.3-4.8-2.4c-0.3-0.4-0.2-1.1,0.2-1.4c0.4-0.3,1.1-0.2,1.4,0.2c0,0,1.3,1.6,3.2,1.6c1.9,0,3.2-1.6,3.2-1.6
        c0.3-0.4,1-0.5,1.4-0.2c0.4,0.3,0.5,1,0.2,1.4C16.7,14.7,15,17,12,17z"/>
    </g>
    <g>
      <path d="M9,10c-0.6,0-1-0.4-1-1s0.4-1,1-1h0c0.6,0,1,0.4,1,1S9.6,10,9,10z"/>
    </g>
    <g>
      <path d="M15,10c-0.6,0-1-0.4-1-1s0.4-1,1-1h0c0.6,0,1,0.4,1,1S15.6,10,15,10z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconSmile',
  props: {
		size: Number,
		fill: String
	}
}
</script>
