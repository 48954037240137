<template>
  <div ref="container" class="bg-gray-100 w-full" style="opacity: 0; transition: opacity 0.5s">
    <div class="gradient gradient-top"/>

    <!-- MENU LIST -->
    <div ref="menuList" class="pt-40 pb-20 flex flex-col justify-center text-center">
      <h2 v-for="menu in menus" :key="menu.name" class="py-6" @click="selectMenu(menu)">
        {{ menu.name }}
      </h2>
    </div>

    <div class="gradient gradient-bottom"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'MenuPicker',
  components: {
  },
  data() {
    return {
      signalSent: false
    }
  },
  computed: {
    ...mapState([
      'menuPickerOpen',
      'menus'
    ]),
    ...mapGetters([
      'menuForTimeOfDay'
    ])
  },
  methods: {
    selectMenu(menu) {
      this.$store.commit('setMenuPickerOpen', !this.menuPickerOpen)
      const newPath = `/${this.$route.params.config}/${menu.slug}`
      if (newPath !== this.$route.path) {
        this.$router.push({path: newPath})
      }

    },
  },
  watch: {
    menuPickerOpen(open) {
      if (open) {
        window.scrollTo(0,0)
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.container.style.opacity = '1'
    }, 100)
  }
}
</script>

<style scoped>
.gradient {
  height: 100px;
  width: 100%;
  position: fixed;
  pointer-events: none;
}

.gradient-top {
  top: 4rem;
  background: linear-gradient(to bottom, #F2F2F2 10%,  rgba(242,242,242,0) 80%, rgba(242,242,242,0) 100%);
}

.gradient-bottom {
  bottom: 0;
  background: linear-gradient(to top, #F2F2F2 10%, rgba(242,242,242,0) 80%, rgba(242,242,242,0) 100%);
}
</style>
