<template>
  <div ref="container" class="w-full" style="opacity: 0; transition: opacity 0.5s">
    <!-- FAVES NAVBAR -->
    <div :class="navbarShadow" class="button-group p-3 bg-gray-100">
      <!-- FAVES TITLE -->
      <div class="px-3">
        <h2>My list</h2>
        <p class="text-gray-700">{{ faveCount }} items selected</p>
      </div>

      <!-- CLOSE BUTTON -->
      <button @click="toggleFaves" class="btn-round p-3">
        <IconClose :size=22 fill="var(--gray-700)"/>
      </button>
    </div>

    <!-- FAVES LIST -->
    <div ref="favesList" class="flex flex-col" >
      <MenuSection
        v-for="(section, index) in faveSections"
        :section="section"
        :key="section.name"
        :firstSection="index === 0"
        :showSectionTitle="true"
      />

      <!-- EMPTY LIST -->
      <div v-if="showNullState" class="flex justify-center items-center">
        <div class="flex flex-col items-center mx-5 text-center">
          <img src="../assets/3hearts.png" alt="3 hearts" class="w-32 mb-4">
          <h2 class="text-gray-500 mb-2 mx-4">You haven't added anything to your list yet</h2>
          <p class="text-gray-500 mx-4">Tap the heart next to each menu item to add to this list</p>
        </div>
      </div>

      <!-- READY BUTTON -->
      <button
        v-if="showReadyButton"
        class="btn-round py-3 px-4 mx-auto mt-5 mb-10"
        @click="signalWaitstaff"
      >
        <IconBell :size=24 fill="var(--gray-700)"/>
        <span class="text-gray-700 ml-1">Ready to order</span>
      </button>

      <!-- CALLED WAITER -->
      <div v-if="signalSent" class="flex items-center mt-8 self-center">
        <IconSmile :size=24 fill="var(--gray-700)"/>
        <span class="text-gray-700 ml-1 text-lg font-semibold">Waitstaff will be with you shortly!</span>
      </div>
    </div>
  </div>
</template>

<script>
import MenuSection from './MenuSection';
import IconBell from './icons/IconBell';
import IconClose from './icons/IconClose';
import IconSmile from './icons/IconSmile';

import { mapState, mapGetters } from 'vuex';

import axios from 'axios'

export default {
  name: 'FavesList',
  components: {
    MenuSection,
    IconBell,
    IconClose,
    IconSmile,
  },
  data() {
    return {
      scrollPosition: null,
      signalSent: false
    }
  },
  computed: {
    ...mapState([
      'business',
      'favesOpen',
      'table'
    ]),
    ...mapGetters([
      'faveCount',
      'faveSections'
    ]),
    navbarShadow() {
      return {
        'shadow-md': this.scrollPosition > 12
      }
    },
    showNullState() {
      return this.faveSections.length === 0 || this.faveSections[0].items.length === 0
    },
    showReadyButton() {
      let ready = this.business.summonWaitstaffEnabled
      ready = ready && this.table
      ready = ready && !this.signalSent
      ready = ready && this.faveCount > 0
      return ready
    }
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    toggleFaves() {
      this.$store.commit('setFavesOpen', !this.favesOpen)
    },
    signalWaitstaff() {
      axios.post('https://api.bend.io/rpc/kfXUG1kxTRO6aqCt9tl4Tg/custom/signal-waitstaff',
        {
          table: this.table
        },
        {
          auth: {
            username: 'kfXUG1kxTRO6aqCt9tl4Tg',
            password: 'u1QzSbS8zCjIPJhMhdjwLpeczXw5FtAJSrV6CDRe'
          }
        }
      ).then(res => {
        console.log(res)
        this.signalSent = true
        setTimeout(() => {
          this.signalSent = false
        }, 15000)
      })
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.updateScroll);
    setTimeout(() => {
      this.$refs.container.style.opacity = '1'
    }, 100)

  },
  destroyed() {
  },
  watch: {
    favesOpen(open) {
      if (open) {
        window.addEventListener('scroll', this.updateScroll);
        window.scrollTo(0,0)
      } else {
        window.removeEventListener('scroll', this.updateScroll)
      }
    },
  }
}
</script>

<style scoped>
.button-group {
  display: flex;
  width: 100vw;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 3;
}
</style>
