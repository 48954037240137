<template>
  <div class="section-list p-20" :class="[sectionPickerOpen ? 'opacity-100' : 'opacity-0 pointer-events-none']">
    <h3
      v-for="section in currentMenu.sections"
      :key="section.id"
      class="my-8"
      @click="updateSection(section._id)"
    >
      {{ section.name }}
    </h3>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'SectionPicker',
  methods: {
    updateSection(id) {
       console.log('updateSection', id)
      this.$store.commit('setSectionId', id)
      this.toggleFab()
    },
    toggleFab() {
      this.$store.commit('setSectionPickerOpen', !this.sectionPickerOpen)
    }
  },
  computed: {
    ...mapState ([
      'sectionPickerOpen'
    ]),
    ...mapGetters ([
      'currentMenu'
    ])
  },
  watch: {
    sectionPickerOpen(open) {
      if (open) {
        window.scrollTo(0,0)
      }
    }
  }
}
</script>

<style scoped>
.section-list {
  color: white;
  background: var(--primary-color);
  text-align: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 3;
  transition: opacity ease-in-out 0.2s;
}
</style>
