<template>
  <div class="flex flex-col items-center text-center m-5">
    <img v-if="business.logo" :src="business.logo" class="h-16 my-4"/>

    <h2>{{ business.name }}</h2>
    <p class="mt-2 mb-4 text-gray-700" v-html="convertLinebreaks(business.indexText)" />

    <button
      v-for="menu in menus"
      :key="menu._id"
      @click="selectMenu(menu.slug)"
      class="btn-menu m-2 flex flex-col w-full"
    >
      <h4>{{menu.name}}</h4>
      <p class="text-gray-700" v-html="convertLinebreaks(menu.description)"/>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MenuIndex',
  computed: {
    ...mapState([
      'menus',
      'business'
    ])
  },
  methods: {
    selectMenu(slug) {
      this.$router.push({path: `/${this.$route.params.config}/${slug}`})
    },
    convertLinebreaks(text) {
      return text.replace(/[\n]/g, "<br>")
    }
  },
}
</script>

<style scoped>
.btn-menu {
  padding: 12px 24px;
  border: 2px solid var(--gray-100);
  border-radius: 8px;
  box-shadow: var(--shadow-button);
  display: flex;
  align-items: center;
}
</style>
